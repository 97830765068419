import { Container, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from 'app/components/Copyright';
import Interpolate from 'app/components/Interpolate';
import Layout from 'app/components/layouts/Layout';
import LocaleContext from 'app/contexts/LocaleContext';
import StaticRouterContext from 'app/contexts/StaticRouterContext';
import { NOT_FOUND_DESCRIPTION, NOT_FOUND_GOTO, NOT_FOUND_TITLE } from 'app/locales/client';
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paragraph: {
    textAlign: 'center',
  },
}));

interface OwnProps {}

type Props = OwnProps;

const NotFound: React.FunctionComponent<Props> = () => {
  const classes = useStyles();
  const { setStatusCode } = useContext(StaticRouterContext);
  const { t } = useContext(LocaleContext);

  setStatusCode(404);

  return (
    <>
      <Helmet>
        <title>{t(NOT_FOUND_TITLE)} | Živá kuchyňa</title>
        <meta property="og:title" content={`404 - ${t(NOT_FOUND_TITLE)}`} />
      </Helmet>

      <div className={classes.paper}>
        <Typography variant="h1" component="h1">
          404!
        </Typography>
        <Typography variant="h3" component="h3">
          <Interpolate resource={NOT_FOUND_TITLE} useDangerouslySetInnerHTML />
        </Typography>
        <p className={classes.paragraph}>
          <Interpolate resource={NOT_FOUND_DESCRIPTION} useDangerouslySetInnerHTML />
          <br />
          <Interpolate resource={NOT_FOUND_GOTO} useDangerouslySetInnerHTML />
        </p>
      </div>

      <Box marginTop={8}>
        <Copyright />
      </Box>
    </>
  );
};

NotFound.displayName = 'NotFound';

export default NotFound;
