import {
  AppBar,
  Button,
  Container,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  AccountCircle,
  ContactMail,
  Euro,
  EuroOutlined,
  FastfoodOutlined,
  Help,
  LocalShippingOutlined,
  MoneyRounded,
  People,
  ReceiptOutlined,
} from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { Permission, Permissions } from 'app/access';
import Banner from 'app/components/Banner';
import AuthContext from 'app/contexts/AuthContext';
import { format } from 'app/helpers/money';
import { displayName } from 'app/helpers/User';
import useEventCallback from 'app/hooks/useEventCallback';
import {
  ADMIN_CREDIT_TRANSACTIONS_ROUTE,
  ADMIN_DELIVERIES_ROUTE,
  ADMIN_MENUS_CREATE_ROUTE,
  ADMIN_MENUS_EDIT_ROUTE,
  ADMIN_MENUS_ROUTE,
  ADMIN_ORDERS_ROUTE,
  ADMIN_USERS_CREATE_ROUTE,
  ADMIN_USERS_EDIT_ROUTE,
  ADMIN_USERS_ROUTE,
  CONTACT_ROUTE,
  CREDIT_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  ORDERS_ROUTE,
  PROFILE_ROUTE,
  REGISTER_ROUTE,
  TERMS_ROUTE,
} from 'app/routes';
import React, { useContext, useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as Logo } from 'images/logo.svg';
import cx from 'classnames';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    color: theme.palette.secondary[300],
    backgroundColor: theme.palette.primary[200],
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  logo: {
    fill: 'currentColor',
    marginRight: theme.spacing(1),
    width: 408.7 / 12.5,
    height: 350.1 / 12.5, // To get 28px height
  },
  login: {
    margin: theme.spacing(1, 1.5),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  username: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  credit: {
    marginLeft: theme.spacing(1),
    fontSize: '1.3em',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  accountButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  accountIconButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbarTitle: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

interface Props {
  children: React.ReactNode;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  showBanner?: boolean;
}

const menuId = 'account-menu';

const Layout: React.FunctionComponent<Props> = ({ children, maxWidth, showBanner = false }) => {
  const classes = useStyles();
  const location = useLocation();
  const { requestUser, logout } = useContext(AuthContext);
  const bannerRef = useRef<HTMLDivElement>();
  const toolbarLogoRef = useRef<HTMLDivElement>();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleProfileMenuOpen = useEventCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClose = useEventCallback(() => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  }, [setAnchorEl]);

  const [menuOpen, setMenuOpen] = React.useState(false);
  const onDrawerToggle = useEventCallback(() => {
    setMenuOpen((s) => !s);
  }, [setMenuOpen]);

  const onDrawerClose = useEventCallback(() => {
    setMenuOpen(false);
  }, [setMenuOpen]);

  const onLogout = useEventCallback(() => {
    setAnchorEl(null);
    logout();
  }, [setAnchorEl]);

  const permissions = requestUser ? Permissions[requestUser.role] : [];
  const canManageUsers = permissions.includes(Permission.UsersManage);
  const canManageMenus = permissions.includes(Permission.MenusManage);
  const canManageOrders = permissions.includes(Permission.OrdersManage);
  const canManageCreditTransactions = permissions.includes(Permission.CreditTransactionsManage);
  const hasAdministration = canManageUsers || canManageMenus || canManageOrders || canManageCreditTransactions;

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {requestUser && (
          <ListItem
            button
            component={RouterLink}
            selected={!!matchPath(HOME_ROUTE, location.pathname)}
            to={HOME_ROUTE}
            onClick={onDrawerClose}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Domov" />
          </ListItem>
        )}
        {requestUser && (
          <ListItem
            button
            component={RouterLink}
            selected={!!matchPath(ORDERS_ROUTE, location.pathname)}
            to={ORDERS_ROUTE}
            onClick={onDrawerClose}
          >
            <ListItemIcon>
              <ReceiptOutlined />
            </ListItemIcon>
            <ListItemText primary="Moje objednávky" />
          </ListItem>
        )}
        {!requestUser && (
          <ListItem
            button
            component={RouterLink}
            to={LOGIN_ROUTE}
            onClick={onDrawerClose}
            selected={!!matchPath(LOGIN_ROUTE, location.pathname)}
          >
            <ListItemIcon>
              <LockOpenIcon />
            </ListItemIcon>
            <ListItemText primary="Prihlásenie" />
          </ListItem>
        )}
        {!requestUser && (
          <ListItem
            button
            component={RouterLink}
            to={REGISTER_ROUTE}
            onClick={onDrawerClose}
            selected={!!matchPath(REGISTER_ROUTE, location.pathname)}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Registrácia" />
          </ListItem>
        )}
        <ListItem
          button
          component={RouterLink}
          to={TERMS_ROUTE}
          onClick={onDrawerClose}
          selected={!!matchPath(TERMS_ROUTE, location.pathname)}
        >
          <ListItemIcon>
            <Help />
          </ListItemIcon>
          <ListItemText primary="O Živej kuchyni" />
        </ListItem>
        <ListItem
          button
          component={RouterLink}
          to={CONTACT_ROUTE}
          onClick={onDrawerClose}
          selected={!!matchPath(CONTACT_ROUTE, location.pathname)}
        >
          <ListItemIcon>
            <ContactMail />
          </ListItemIcon>
          <ListItemText primary="Kontakt" />
        </ListItem>
      </List>
      {hasAdministration && (
        <>
          <Divider />
          <List>
            {canManageUsers && (
              <ListItem
                button
                component={RouterLink}
                selected={
                  !!matchPath(ADMIN_USERS_ROUTE, location.pathname) ||
                  !!matchPath(ADMIN_USERS_CREATE_ROUTE, location.pathname) ||
                  !!matchPath(ADMIN_USERS_EDIT_ROUTE, location.pathname)
                }
                to={ADMIN_USERS_ROUTE}
                onClick={onDrawerClose}
              >
                <ListItemIcon>
                  <People />
                </ListItemIcon>
                <ListItemText primary="Používatelia" />
              </ListItem>
            )}
            {canManageMenus && (
              <ListItem
                button
                component={RouterLink}
                selected={
                  !!matchPath(ADMIN_MENUS_ROUTE, location.pathname) ||
                  !!matchPath(ADMIN_MENUS_CREATE_ROUTE, location.pathname) ||
                  !!matchPath(ADMIN_MENUS_EDIT_ROUTE, location.pathname)
                }
                to={ADMIN_MENUS_ROUTE}
                onClick={onDrawerClose}
              >
                <ListItemIcon>
                  <FastfoodOutlined />
                </ListItemIcon>
                <ListItemText primary="Menu dňa" />
              </ListItem>
            )}
            {canManageOrders && (
              <ListItem
                button
                component={RouterLink}
                selected={!!matchPath(ADMIN_ORDERS_ROUTE, location.pathname)}
                to={ADMIN_ORDERS_ROUTE}
                onClick={onDrawerClose}
              >
                <ListItemIcon>
                  <ReceiptOutlined />
                </ListItemIcon>
                <ListItemText primary="Objednávky" />
              </ListItem>
            )}{' '}
            {canManageOrders && (
              <ListItem
                button
                component={RouterLink}
                selected={!!matchPath(ADMIN_DELIVERIES_ROUTE, location.pathname)}
                to={ADMIN_DELIVERIES_ROUTE}
                onClick={onDrawerClose}
              >
                <ListItemIcon>
                  <LocalShippingOutlined />
                </ListItemIcon>
                <ListItemText primary="Rozvozy" />
              </ListItem>
            )}{' '}
            {canManageCreditTransactions && (
              <ListItem
                button
                component={RouterLink}
                selected={!!matchPath(ADMIN_CREDIT_TRANSACTIONS_ROUTE, location.pathname)}
                to={ADMIN_CREDIT_TRANSACTIONS_ROUTE}
                onClick={onDrawerClose}
              >
                <ListItemIcon>
                  <EuroOutlined />
                </ListItemIcon>
                <ListItemText primary="Transakcie" />
              </ListItem>
            )}
          </List>
        </>
      )}
    </div>
  );

  const container = typeof window !== 'undefined' ? window.document.body : undefined;

  useEffect(() => {
    if (showBanner) {
      const height = bannerRef.current.clientHeight;
      const svg = bannerRef.current.querySelector('svg');
      const svgHeight = svg.clientHeight;
      const halfDiff = (height - svgHeight) / 2;
      const toolbarLogo = toolbarLogoRef.current;

      const handler = () => {
        const scale = Math.max((height - halfDiff - window.scrollY) / (height - halfDiff), 0);
        svg.style.transform = `scale(${scale})`;
        svg.style.transformOrigin = '50% 100%';

        if (toolbarLogo) {
          if (window.scrollY < height - halfDiff) {
            toolbarLogo.style.transform = 'translateY(14px)';
            toolbarLogo.style.opacity = '0';
          } else {
            toolbarLogo.style.transition = '.5s ease';
            toolbarLogo.style.transform = 'translateY(0)';
            toolbarLogo.style.opacity = '1';
          }
        }
      };

      handler();

      document.addEventListener('scroll', handler);

      return () => {
        svg.style.transform = '';
        svg.style.transformOrigin = '';

        if (toolbarLogo) {
          toolbarLogo.style.transition = '';
          toolbarLogo.style.transform = '';
          toolbarLogo.style.opacity = '';
        }

        document.removeEventListener('scroll', handler);
      };
    }
  }, [showBanner, bannerRef]);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" elevation={0} className={classes.appBar} color="secondary">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            className={classes.menuButton}
            onClick={onDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
          <div ref={toolbarLogoRef} className={classes.toolbarTitle}>
            <Logo viewBox="0 0 408.7 350.1" className={cx(classes.logo, requestUser && classes.hideMobile)} />
            <Typography variant="h6" className={cx(classes.title, requestUser && classes.hideMobile)}>
              Živá kuchyňa
            </Typography>
          </div>
          {!requestUser ? (
            <Button to={LOGIN_ROUTE} component={RouterLink} color="inherit" className={classes.login}>
              Prihlásenie
            </Button>
          ) : (
            <>
              <Button color="inherit" component={RouterLink} to={CREDIT_ROUTE}>
                <MoneyRounded />
                <span className={classes.credit}>{format(requestUser.credit)}</span>
              </Button>
              <Button onClick={handleProfileMenuOpen} color="inherit" className={classes.accountButton}>
                <AccountCircle />
                <span className={classes.username}>{requestUser.firstName || displayName(requestUser)}</span>
              </Button>
              <IconButton
                onClick={handleProfileMenuOpen}
                color="inherit"
                edge="end"
                className={classes.accountIconButton}
              >
                <AccountCircle />
              </IconButton>
            </>
          )}
        </Toolbar>
      </AppBar>
      {requestUser && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose} divider>
            <Link color="inherit" component={RouterLink} to={PROFILE_ROUTE} underline="none">
              Profil
            </Link>
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <Link color="inherit" component={RouterLink} to={LOGIN_ROUTE} underline="none">
              Odhlásiť
            </Link>
          </MenuItem>
        </Menu>
      )}
      <nav className={classes.drawer} aria-label="Hlavné menu">
        <Hidden mdUp implementation="css">
          <SwipeableDrawer
            container={container}
            variant="temporary"
            open={menuOpen}
            onClose={onDrawerToggle}
            onOpen={onDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>{' '}
      </nav>
      <Container component="main" maxWidth={maxWidth} className={classes.content}>
        <div className={classes.toolbar} />
        {showBanner && <Banner ref={bannerRef} />}
        {children}
      </Container>
    </div>
  );
};

Layout.displayName = 'Layout';

export default React.memo<Props>(Layout);
