import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Copyright from 'app/components/Copyright';
import Layout from 'app/components/layouts/Layout';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

const AuthLayout: React.FunctionComponent<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <Layout maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
      </div>
      {children}
      <Box marginTop={8}>
        <Copyright />
      </Box>
    </Layout>
  );
};

AuthLayout.displayName = 'AuthLayout';

export default AuthLayout;
