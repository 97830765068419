import Layout from 'app/components/layouts/Layout';
import React from 'react';

interface OwnProps {
  path: string;
  element: React.ReactNode;
  showBanner?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

const Route: React.FunctionComponent<OwnProps> = ({ element, showBanner = false, maxWidth }) => {
  return (
    <Layout maxWidth={maxWidth ?? false} showBanner={showBanner}>
      {element}
    </Layout>
  );
};

Route.displayName = 'LoggedRoute';

export default Route;
