import Loading from 'app/components/Loading';
import RLoadable from 'react-loadable';

type OptionalLoadingAndDelay<T> = Omit<T, 'delay' | 'loading'> &
  {
    [P in Extract<keyof T, 'delay' | 'loading'>]?: T[P];
  };

type Options<Props, Exports extends Record<string, any>> =
  | OptionalLoadingAndDelay<RLoadable.OptionsWithoutRender<Props>>
  | OptionalLoadingAndDelay<RLoadable.OptionsWithRender<Props, Exports>>;

const Loadable = function LoadableFn<Props, Exports extends Record<string, any> = Record<string, any>>(
  options: Options<Props, Exports>,
) {
  return RLoadable<Props, Exports>({
    loading: Loading,
    delay: 300,
    ...options,
  });
};

type OptionsWithMap<Props, Exports extends { [key: string]: any } = Record<string, any>> = OptionalLoadingAndDelay<
  RLoadable.OptionsWithMap<Props, Exports>
>;

Loadable.Map = function Map<Props, Exports extends { [key: string]: any } = Record<string, any>>(
  options: OptionsWithMap<Props, Exports>,
) {
  return RLoadable.Map<Props, Exports>({
    loading: Loading,
    delay: 300,
    ...options,
  });
};

export default Loadable;
