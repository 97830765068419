import React from 'react';
import { RequestUserFragment } from 'app/schema.client.types';

export interface AuthContextValue {
  requestUser: RequestUserFragment;
  login(requestUser: RequestUserFragment);
  logout();
  setSetting(key: string, value: any);
}

const AuthContext = React.createContext<AuthContextValue>({
  requestUser: null,
  login(requestUser: RequestUserFragment) {
    this.requestUser = requestUser;
  },
  logout() {
    this.requestUser = null;
  },
  setSetting(key: string, value: any) {
    this.requestUser.settings = this.requestUser.settings || {};
    this.requestUser.settings[key] = value;
  },
});

export default AuthContext;
