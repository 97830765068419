import AuthLayout from 'app/components/layouts/AuthLayout';
import React from 'react';

interface OwnProps {
  path: string;
  element: React.ReactNode;
}

const AuthRoute: React.FunctionComponent<OwnProps> = ({ element }) => {
  return <AuthLayout>{element}</AuthLayout>;
};

AuthRoute.displayName = 'AuthRoute';

export default AuthRoute;
