import { Button, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Copyright from 'app/components/Copyright';
import Interpolate from 'app/components/Interpolate';
import Layout from 'app/components/layouts/Layout';
import LocaleContext from 'app/contexts/LocaleContext';
import useEventCallback from 'app/hooks/useEventCallback';
import {
  SERVER_ERROR_APOLOGIES,
  SERVER_ERROR_MESSAGE,
  SERVER_ERROR_MESSAGE_WITH_REPORT,
  SERVER_ERROR_REPORT,
  SERVER_ERROR_SENTRY_ERROR_FORM_ENTRY,
  SERVER_ERROR_SENTRY_ERROR_GENERIC,
  SERVER_ERROR_SENTRY_LABEL_CLOSE,
  SERVER_ERROR_SENTRY_LABEL_COMMENTS,
  SERVER_ERROR_SENTRY_LABEL_EMAIL,
  SERVER_ERROR_SENTRY_LABEL_NAME,
  SERVER_ERROR_SENTRY_LABEL_SUBMIT,
  SERVER_ERROR_SENTRY_SUBTITLE,
  SERVER_ERROR_SENTRY_SUBTITLE2,
  SERVER_ERROR_SENTRY_SUCCESS_MESSAGE,
  SERVER_ERROR_SENTRY_TITLE,
  SERVER_ERROR_TITLE,
} from 'app/locales/client';
import Sentry from 'app/services/sentry';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paragraph: {
    textAlign: 'center',
  },
}));

interface OwnProps {}

type Props = OwnProps;

const ServerError: React.FunctionComponent<Props> = () => {
  const { t, language } = useContext(LocaleContext);
  const classes = useStyles();

  const onReport = useEventCallback(() => {
    if (Sentry && Sentry.lastEventId()) {
      Sentry.showReportDialog({
        lang: language,
        title: t(SERVER_ERROR_SENTRY_TITLE),
        subtitle: t(SERVER_ERROR_SENTRY_SUBTITLE),
        subtitle2: t(SERVER_ERROR_SENTRY_SUBTITLE2),
        labelName: t(SERVER_ERROR_SENTRY_LABEL_NAME),
        labelEmail: t(SERVER_ERROR_SENTRY_LABEL_EMAIL),
        labelComments: t(SERVER_ERROR_SENTRY_LABEL_COMMENTS),
        labelClose: t(SERVER_ERROR_SENTRY_LABEL_CLOSE),
        labelSubmit: t(SERVER_ERROR_SENTRY_LABEL_SUBMIT),
        errorGeneric: t(SERVER_ERROR_SENTRY_ERROR_GENERIC),
        errorFormEntry: t(SERVER_ERROR_SENTRY_ERROR_FORM_ENTRY),
        successMessage: t(SERVER_ERROR_SENTRY_SUCCESS_MESSAGE),
      });
    }
  }, [t, language]);

  return (
    <>
      <Helmet>
        <title>{t(SERVER_ERROR_TITLE)} | Živá kuchyňa</title>
        <meta property="og:title" content={`500 - ${t(SERVER_ERROR_TITLE)}`} />
      </Helmet>

      <div className={classes.paper}>
        <Typography variant="h1" component="h1">
          500!
        </Typography>
        <Typography variant="h3" component="h3">
          <Interpolate resource={SERVER_ERROR_TITLE} useDangerouslySetInnerHTML />
        </Typography>
        <p className={classes.paragraph}>
          <span
            dangerouslySetInnerHTML={{ __html: t(Sentry ? SERVER_ERROR_MESSAGE_WITH_REPORT : SERVER_ERROR_MESSAGE) }}
          />

          <br />

          {t(SERVER_ERROR_APOLOGIES)}

          {Sentry && (
            <Button color="primary" onClick={onReport}>
              {t(SERVER_ERROR_REPORT)}
            </Button>
          )}
        </p>
      </div>

      <Box marginTop={8}>
        <Copyright />
      </Box>
    </>
  );
};

export default ServerError;
