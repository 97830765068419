import LocaleContext from 'app/contexts/LocaleContext';
import { INTERPOLATE_REGEXP, Resource } from 'app/services/i18n';
import React from 'react';

interface OwnProps {
  className?: string;
  parent?: string;
  resource: Resource;
  useDangerouslySetInnerHTML?: boolean;
  dangerouslySetInnerHTMLPartElement?: string;
  components?: React.ReactNode[];
  count?: number;
}

type Props = OwnProps & {
  [name: string]: React.ReactNode;
};

class Interpolate extends React.Component<Props> {
  public static defaultProps = {
    parent: 'span',
    dangerouslySetInnerHTMLPartElement: 'span',
    useDangerouslySetInnerHTML: false,
  };

  public render() {
    const {
      parent,
      resource,
      useDangerouslySetInnerHTML,
      dangerouslySetInnerHTMLPartElement,
      children,
      className,
      components,
      ...rest
    } = this.props;
    const element = parent || 'span';

    return (
      <LocaleContext.Consumer>
        {({ t }) => {
          const message = t(resource, { count: rest.count });

          if (ENV.ENVIRONMENT !== 'production') {
            if (useDangerouslySetInnerHTML) {
              // Detect variables inside HTML tags
              const re = /<([^>]+)>.*?<\/\1>/g;
              let match;
              do {
                match = re.exec(message);
                if (match && INTERPOLATE_REGEXP.test(match[0])) {
                  throw new Error(
                    `Interpolate: Cannot use 'useDangerouslySetInnerHTML' prop with key that contains HTML around variable. It will produce invalid HTML and break React with very strange error.`,
                  );
                }
              } while (match);
            }
          }

          const parts = this.replaceTag(
            message,
            components,
            rest,
            useDangerouslySetInnerHTML,
            dangerouslySetInnerHTMLPartElement,
          );

          return React.createElement(element, { className }, ...parts);
        }}
      </LocaleContext.Consumer>
    );
  }
  private replaceTag(
    message: string,
    components: React.ReactNode[],
    variables: { [name: string]: React.ReactNode },
    useDangerouslySetInnerHTML: boolean,
    dangerouslySetInnerHTMLPartElement: string,
  ): React.ReactNode[] {
    if (!/<(\d+)>(.*?)<\/\1>/g.test(message)) {
      return this.replaceVariables(message, variables, useDangerouslySetInnerHTML, dangerouslySetInnerHTMLPartElement);
    }

    const parts = message.split(/<(\d+)>(.*?)<\/\1>/g);
    const result: React.ReactNode[] = [];
    for (let i = 0; i < parts.length; i++) {
      if (i % 3 === 0 && parts[i].length) {
        result.push(
          this.replaceVariables(parts[i], variables, useDangerouslySetInnerHTML, dangerouslySetInnerHTMLPartElement),
        );
      } else if (i % 3 === 2) {
        const child = parts[i];
        // Element
        const comp = components[parseInt(parts[i - 1], 10)];
        const node = React.isValidElement<{ children: React.ReactNode[] }>(comp)
          ? React.cloneElement(comp, {
              children: this.replaceTag(
                child,
                components,
                variables,
                useDangerouslySetInnerHTML,
                dangerouslySetInnerHTMLPartElement,
              ),
            })
          : comp;
        result.push(node);
      }
    }

    return result;
  }

  private replaceVariables(
    message: string,
    variables: { [name: string]: React.ReactNode },
    useDangerouslySetInnerHTML: boolean,
    dangerouslySetInnerHTMLPartElement: string,
  ): React.ReactNode[] {
    return message.split(INTERPOLATE_REGEXP).reduce<React.ReactNode[]>((acc, cur, index) => {
      let child;

      if (index % 2 === 0) {
        if (cur.length === 0) {
          return acc;
        }

        child = useDangerouslySetInnerHTML
          ? React.createElement(dangerouslySetInnerHTMLPartElement, {
              dangerouslySetInnerHTML: { __html: cur },
            })
          : cur;
      } else {
        child = variables[cur];
      }

      acc.push(child);
      return acc;
    }, []);
  }
}

export default Interpolate;
