import { useMutation, useQuery, useSubscription } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { App_ProfileUpdated, App_RequestUser, App_Logout } from 'app/components/App/App.graphql';
import ErrorBoundary from 'app/components/ErrorBoundary';
import NotFound from 'app/components/pages/NotFound/NotFound';
import AuthRoute from 'app/components/routes/AuthRoute';
import Route from 'app/components/routes/Route';
import ScrollToTop from 'app/components/ScrollToTop';
import AuthContext, { AuthContextValue } from 'app/contexts/AuthContext';
import LocaleContext, { LocaleContextValue } from 'app/contexts/LocaleContext';
import { dateFnsLocales } from 'app/helpers/date';
import Loadable from 'app/helpers/Loadable';
import {
  ADMIN_CREDIT_TRANSACTIONS_ROUTE,
  ADMIN_DELIVERIES_ROUTE,
  ADMIN_MENUS_CREATE_ROUTE,
  ADMIN_MENUS_EDIT_ROUTE,
  ADMIN_MENUS_ROUTE,
  ADMIN_ORDERS_CREATE_ROUTE,
  ADMIN_ORDERS_EDIT_ROUTE,
  ADMIN_ORDERS_ROUTE,
  ADMIN_USERS_CREATE_ROUTE,
  ADMIN_USERS_EDIT_ROUTE,
  ADMIN_USERS_ROUTE,
  CONTACT_ROUTE,
  CREDIT_ROUTE,
  FORGOTTEN_PASSWORD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  ORDERS_ROUTE,
  PROFILE_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  TERMS_ROUTE,
} from 'app/routes';
import {
  App_ProfileUpdatedSubscription,
  App_ProfileUpdatedSubscriptionVariables,
  App_RequestUserQuery,
  App_RequestUserQueryVariables,
  App_LogoutMutation,
  App_LogoutMutationVariables,
  Me,
} from 'app/schema.client.types';
import { interpolate, InterpolateOptions, Resource } from 'app/services/i18n';
import { getLogger } from 'app/services/logger';
import Sentry from 'app/services/sentry';
import fonts from 'app/styles/fonts';
import theme from 'app/theme';
import { format } from 'date-fns';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route as RouterRoute, Routes } from 'react-router';

class LocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date) {
    return format(date, 'LLLL yyyy', { locale: this.locale });
  }
  getDatePickerHeaderText(date) {
    return format(date, 'd. MMM yyyy', { locale: this.locale });
  }
  getDateTimePickerHeaderText(date) {
    return format(date, 'd. MMM', { locale: this.locale });
  }
}

const logger = getLogger('components/App/App');

const Login = Loadable({
  loader: () => import('app/components/pages/auth/Login/Login' /* webpackChunkName: "auth.Login" */),
  modules: ['app/components/pages/auth/Login/Login'],
  webpack: () => [require.resolveWeak('app/components/pages/auth/Login/Login')],
});

const ForgottenPassword = Loadable({
  loader: () =>
    import(
      'app/components/pages/auth/ForgottenPassword/ForgottenPassword' /* webpackChunkName: "auth.ForgottenPassword" */
    ),
  modules: ['app/components/pages/auth/ForgottenPassword/ForgottenPassword'],
  webpack: () => [require.resolveWeak('app/components/pages/auth/ForgottenPassword/ForgottenPassword')],
});

const ResetPassword = Loadable({
  loader: () =>
    import('app/components/pages/auth/ResetPassword/ResetPassword' /* webpackChunkName: "auth.ResetPassword" */),
  modules: ['app/components/pages/auth/ResetPassword/ResetPassword'],
  webpack: () => [require.resolveWeak('app/components/pages/auth/ResetPassword/ResetPassword')],
});

const Register = Loadable({
  loader: () => import('app/components/pages/auth/Register/Register' /* webpackChunkName: "auth.Register" */),
  modules: ['app/components/pages/auth/Register/Register'],
  webpack: () => [require.resolveWeak('app/components/pages/auth/Register/Register')],
});

const Dashboard = Loadable({
  loader: () => import('app/components/pages/Dashboard/Dashboard' /* webpackChunkName: "Dashboard" */),
  modules: ['app/components/pages/Dashboard/Dashboard'],
  webpack: () => [require.resolveWeak('app/components/pages/Dashboard/Dashboard')],
});

const Users = Loadable({
  loader: () => import('app/components/pages/admin/Users/Users' /* webpackChunkName: "admin.Users" */),
  modules: ['app/components/pages/admin/Users/Users'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/Users/Users')],
});

const NewUser = Loadable({
  loader: () => import('app/components/pages/admin/NewUser/NewUser' /* webpackChunkName: "admin.NewUser" */),
  modules: ['app/components/pages/admin/NewUser/NewUser'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/NewUser/NewUser')],
});

const EditUser = Loadable({
  loader: () => import('app/components/pages/admin/EditUser/EditUser' /* webpackChunkName: "admin.EditUser" */),
  modules: ['app/components/pages/admin/EditUser/EditUser'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/EditUser/EditUser')],
});

const Menus = Loadable({
  loader: () => import('app/components/pages/admin/Menus/Menus' /* webpackChunkName: "admin.Menus" */),
  modules: ['app/components/pages/admin/Menus/Menus'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/Menus/Menus')],
});

const NewMenu = Loadable({
  loader: () => import('app/components/pages/admin/NewMenu/NewMenu' /* webpackChunkName: "admin.NewMenu" */),
  modules: ['app/components/pages/admin/NewMenu/NewMenu'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/NewMenu/NewMenu')],
});

const EditMenu = Loadable({
  loader: () => import('app/components/pages/admin/EditMenu/EditMenu' /* webpackChunkName: "admin.EditMenu" */),
  modules: ['app/components/pages/admin/EditMenu/EditMenu'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/EditMenu/EditMenu')],
});

const Profile = Loadable({
  loader: () => import('app/components/pages/Profile/Profile' /* webpackChunkName: "Profile" */),
  modules: ['app/components/pages/Profile/Profile'],
  webpack: () => [require.resolveWeak('app/components/pages/Profile/Profile')],
});

const AdminOrders = Loadable({
  loader: () => import('app/components/pages/admin/Orders/Orders' /* webpackChunkName: "admin.Orders" */),
  modules: ['app/components/pages/admin/Orders/Orders'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/Orders/Orders')],
});

const AdminNewOrder = Loadable({
  loader: () => import('app/components/pages/admin/NewOrder/NewOrder' /* webpackChunkName: "admin.NewOrder" */),
  modules: ['app/components/pages/admin/NewOrder/NewOrder'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/NewOrder/NewOrder')],
});

const AdminEditOrder = Loadable({
  loader: () => import('app/components/pages/admin/EditOrder/EditOrder' /* webpackChunkName: "admin.EditOrder" */),
  modules: ['app/components/pages/admin/EditOrder/EditOrder'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/EditOrder/EditOrder')],
});

const AdminDeliveries = Loadable({
  loader: () => import('app/components/pages/admin/Deliveries/Deliveries' /* webpackChunkName: "admin.Deliveries" */),
  modules: ['app/components/pages/admin/Deliveries/Deliveries'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/Deliveries/Deliveries')],
});

const CreditTransactions = Loadable({
  loader: () =>
    import(
      'app/components/pages/admin/CreditTransactions/CreditTransactions' /* webpackChunkName: "admin.CreditTransactions" */
    ),
  modules: ['app/components/pages/admin/CreditTransactions/CreditTransactions'],
  webpack: () => [require.resolveWeak('app/components/pages/admin/CreditTransactions/CreditTransactions')],
});

const Terms = Loadable({
  loader: () => import('app/components/pages/Terms/Terms' /* webpackChunkName: "Terms" */),
  modules: ['app/components/pages/Terms/Terms'],
  webpack: () => [require.resolveWeak('app/components/pages/Terms/Terms')],
});

const Contact = Loadable({
  loader: () => import('app/components/pages/Contact/Contact' /* webpackChunkName: "Contact" */),
  modules: ['app/components/pages/Contact/Contact'],
  webpack: () => [require.resolveWeak('app/components/pages/Contact/Contact')],
});

const Credit = Loadable({
  loader: () => import('app/components/pages/Credit/Credit' /* webpackChunkName: "Credit" */),
  modules: ['app/components/pages/Credit/Credit'],
  webpack: () => [require.resolveWeak('app/components/pages/Credit/Credit')],
});

const Orders = Loadable({
  loader: () => import('app/components/pages/Orders/Orders' /* webpackChunkName: "Orders" */),
  modules: ['app/components/pages/Orders/Orders'],
  webpack: () => [require.resolveWeak('app/components/pages/Orders/Orders')],
});

interface OwnProps {
  language: string;
}

type Props = OwnProps;

const snackbarOrigin: SnackbarOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const App: React.FunctionComponent<Props> = ({ language }) => {
  const { data: requestUserData } = useQuery<App_RequestUserQuery, App_RequestUserQueryVariables>(App_RequestUser, {});
  const requestUser = requestUserData?.me;
  const [logoutExecute] = useMutation<App_LogoutMutation, App_LogoutMutationVariables>(App_Logout, {
    refetchQueries: ['App_RequestUser', 'Profile_Load'],
  });

  const [authContext, setAuthContext] = useState<AuthContextValue>({
    requestUser,
    login(user: Me) {
      setAuthContext((state) => ({
        ...state,
        requestUser: user,
      }));
      if (Sentry) {
        Sentry.configureScope((scope) => {
          scope.setUser(user);
        });
      }
    },
    async logout() {
      try {
        await logoutExecute();
      } catch (err) {
        logger.error(err);
      }
      setAuthContext((state) => ({
        ...state,
        requestUser: null,
      }));
      if (Sentry) {
        Sentry.configureScope((scope) => {
          scope.setUser(null);
        });
      }
    },
    setSetting(key: string, value: any) {
      setAuthContext((state) => ({
        ...state,
        requestUser: {
          ...state.requestUser,
          settings: {
            ...(state.requestUser.settings || {}),
            [key]: value,
          },
        },
      }));
    },
  });
  const [localeContext, setLocaleContext] = useState<LocaleContextValue>({
    language,
    t: (resource: Resource, options?: InterpolateOptions) => interpolate(language, resource, options),
    formatDate: (date: string | Date, resource: Resource): string =>
      format(new Date(date), localeContext.t(resource), { locale: dateFnsLocales[localeContext.language] }),
  });

  const { data: profileUpdatedData } = useSubscription<
    App_ProfileUpdatedSubscription,
    App_ProfileUpdatedSubscriptionVariables
  >(App_ProfileUpdated, {
    skip: !requestUser,
  });

  const updateProfile = authContext.login;
  useEffect(() => {
    if (requestUser) {
      updateProfile(requestUser);
    }
  }, [requestUser, updateProfile]);

  useEffect(() => {
    if (profileUpdatedData?.profileUpdated) {
      updateProfile(profileUpdatedData.profileUpdated);
    }
  }, [updateProfile, profileUpdatedData]);

  useEffect(() => {
    // https://material-ui.com/guides/server-rendering/
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <ErrorBoundary>
      <Helmet>
        <meta name="theme-color" content={theme.palette.primary.main} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={dateFnsLocales[localeContext.language]}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3} anchorOrigin={snackbarOrigin}>
            <ScrollToTop>
              <AuthContext.Provider value={authContext}>
                <LocaleContext.Provider value={localeContext}>
                  <div>
                    <Routes>
                      <AuthRoute path={LOGIN_ROUTE} element={<Login />} />
                      <AuthRoute path={FORGOTTEN_PASSWORD_ROUTE} element={<ForgottenPassword />} />
                      <AuthRoute path={RESET_PASSWORD_ROUTE} element={<ResetPassword />} />
                      <AuthRoute path={REGISTER_ROUTE} element={<Register />} />

                      <Route path={HOME_ROUTE} element={<Dashboard />} maxWidth="lg" />
                      <Route path={PROFILE_ROUTE} element={<Profile />} maxWidth="lg" />

                      <Route path={ADMIN_USERS_ROUTE} element={<Users />} />
                      <Route path={ADMIN_USERS_CREATE_ROUTE} element={<NewUser />} />
                      <Route path={ADMIN_USERS_EDIT_ROUTE} element={<EditUser />} />

                      <Route path={ADMIN_MENUS_ROUTE} element={<Menus />} />
                      <Route path={ADMIN_MENUS_CREATE_ROUTE} element={<NewMenu />} />
                      <Route path={ADMIN_MENUS_EDIT_ROUTE} element={<EditMenu />} />

                      <Route path={ADMIN_ORDERS_ROUTE} element={<AdminOrders />} />
                      <Route path={ADMIN_ORDERS_CREATE_ROUTE} element={<AdminNewOrder />} />
                      <Route path={ADMIN_ORDERS_EDIT_ROUTE} element={<AdminEditOrder />} />

                      <Route path={ADMIN_DELIVERIES_ROUTE} element={<AdminDeliveries />} />
                      <Route path={ADMIN_CREDIT_TRANSACTIONS_ROUTE} element={<CreditTransactions />} />

                      <Route path={TERMS_ROUTE} element={<Terms />} showBanner />
                      <Route path={CONTACT_ROUTE} element={<Contact />} showBanner />
                      <Route path={CREDIT_ROUTE} element={<Credit />} maxWidth="lg" />
                      <Route path={ORDERS_ROUTE} element={<Orders />} />

                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </div>
                </LocaleContext.Provider>
              </AuthContext.Provider>
            </ScrollToTop>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
