import { dateFnsLocales } from 'app/helpers/date';
import { interpolate, InterpolateOptions, Resource } from 'app/services/i18n';
import format from 'date-fns/format';
import React from 'react';

export interface LocaleContextValue {
  language: string;
  t(resource: Resource, options?: InterpolateOptions): string;
  formatDate(date: string | Date, resource: Resource): string;
}

const defaultValue: LocaleContextValue = {
  language: 'sk',
  t: (resource: Resource, options?: InterpolateOptions): string => interpolate('sk', resource, options),
  formatDate: (date: string | Date, resource: Resource): string =>
    format(new Date(date), defaultValue.t(resource), { locale: dateFnsLocales[defaultValue.language] }),
};

const LocaleContext = React.createContext<LocaleContextValue>(defaultValue);

export default LocaleContext;
