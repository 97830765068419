export enum Role {
  User = 'user',
  Admin = 'admin',
}

export enum Permission {
  JobRead = 'JobRead',
  NotificationsRead = 'NotificationsRead',
  UsersManage = 'UsersManage',
  MenusManage = 'MenusManage',
  OrdersManage = 'OrdersManage',
  CreditTransactionsManage = 'CreditTransactionsManage',
}

const defaultPermissions = [Permission.NotificationsRead];

export const Permissions = {
  [Role.User]: defaultPermissions,
  [Role.Admin]: [
    ...defaultPermissions,
    Permission.CreditTransactionsManage,
    Permission.JobRead,
    Permission.MenusManage,
    Permission.OrdersManage,
    Permission.UsersManage,
  ],
};
