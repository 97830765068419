let Sentry;
let Tracing;

if (ENV.ENVIRONMENT === 'production') {
  Sentry = ENV.SERVER ? require('@sentry/node') : require('@sentry/browser');
  Tracing = require('@sentry/tracing');

  const integrations = [];
  if (ENV.SERVER) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { RewriteFrames } = require('@sentry/integrations');
    integrations.push(new RewriteFrames());
    integrations.push(new Sentry.Integrations.Http({ tracing: true }));
  } else {
    integrations.push(new Tracing.Integrations.BrowserTracing());
  }

  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    release: ENV.SENTRY_RELEASE,
    environment: ENV.SENTRY_ENVIRONMENT,
    integrations,
    tracesSampleRate: ENV.SENTRY_TRACES_SAMPLE_RATE,
  });
}

export default Sentry;

function isPromise<T, S>(obj: PromiseLike<T> | S): obj is PromiseLike<T> {
  return (
    !!obj &&
    (typeof obj === 'object' || typeof obj === 'function') &&
    typeof (obj as PromiseLike<T>).then === 'function'
  );
}

export function trace<TArgs extends any[], TReturnType>(
  fn: (...args: TArgs) => TReturnType,
  op: string,
  description: string,
): (...args: TArgs) => TReturnType {
  if (!Sentry || !fn) {
    return fn;
  }

  return (...args: TArgs): TReturnType => {
    const transaction = Sentry.getCurrentHub().getScope().getTransaction();
    let span;
    if (transaction) {
      span = transaction.startChild({
        op,
        description,
      });
    }

    const result = fn(...args);
    if (isPromise(result)) {
      result.then(
        (res: unknown) => {
          if (span) {
            span.finish();
          }
        },
        (err) => {
          if (span) {
            span.finish();
          }
        },
      );

      return result;
    } else {
      if (span) {
        span.finish();
      }

      return result;
    }
  };
}
