import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import possibleTypes from 'app/possibleTypes.json';
import { getLogger } from 'app/services/logger';
import React from 'react';

const logger = getLogger('services/apollo');

export type Client = ApolloClient<any>;

const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export default function createApollo(
  token?: string,
  cacheState?: NormalizedCacheObject,
): ApolloClient<NormalizedCacheObject> {
  const cache = new InMemoryCache({ possibleTypes: possibleTypes.possibleTypes });

  if (cacheState) {
    cache.restore(cacheState);
  }

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
      logger.error(networkError);
    }
  });

  const httpLink = new HttpLink({
    uri: `${ENV.BASE_URL}/graphql`,
    credentials: 'same-origin',
    headers: {
      'CSRF-Token': csrfToken,
    },
  });

  const wsLink = new WebSocketLink({
    uri: `${ENV.BASE_URL.replace(/^http/, 'ws')}/graphql`,
    options: {
      reconnect: true,
      timeout: 30 * 1000,
    },
  });

  const endpointLink = ApolloLink.split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const link = ApolloLink.from([errorLink, endpointLink]);

  return new ApolloClient({
    cache,
    link,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        pollInterval: 10 * 60 * 1000, // Always refresh after 10 mins
      },
    },
    ssrForceFetchDelay: 100,
  });
}
