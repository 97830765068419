import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { HOME_ROUTE } from 'app/routes';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {}

const Copyright: React.FunctionComponent<Props> = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <Link color="inherit" component={RouterLink} to={HOME_ROUTE}>
      Živá kuchyňa
    </Link>{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
);

Copyright.displayName = 'Copyright';

export default React.memo<Props>(Copyright);
