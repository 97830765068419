import { CircularProgress, Fade } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LocaleContext from 'app/contexts/LocaleContext';
import { LOAD_ERROR_MESSAGE } from 'app/locales/client';
import { getLogger } from 'app/services/logger';
import React, { useContext } from 'react';

const logger = getLogger('components/Loading');

interface OwnProps {
  error?: any;
  pastDelay?: boolean;
}

const Loading: React.FunctionComponent<OwnProps> = ({ error, pastDelay = true }) => {
  const { t } = useContext(LocaleContext);
  if (error) {
    logger.error(error);
    return <Alert severity="error">{t(LOAD_ERROR_MESSAGE)}</Alert>;
  }

  return (
    <div>
      <Fade
        in={pastDelay}
        style={{
          transitionDelay: pastDelay ? '300ms' : '0ms',
        }}
        unmountOnExit
      >
        <CircularProgress />
      </Fade>
    </div>
  );
};

export default Loading;
