export function round(price: number, decimals = 2) {
  return Math.floor(price * 10 ** decimals) / 10 ** decimals;
}

export function priceWithVat(price = 0, vatPercent = 0) {
  return round(price * (1 + vatPercent / 100));
}

export function vat(price = 0, vatPercent = 0) {
  return round(price * (vatPercent / 100));
}

export function priceWithoutVat(price = 0, vatPercent = 0) {
  return round(price / (1 + vatPercent / 100));
}

export function priceWithDiscount(price = 0, discountPercent = 0) {
  return round(price * (1 - discountPercent / 100));
}

export function discount(price = 0, discountPercent = 0) {
  return round(price * (discountPercent / 100));
}

const formatter = new Intl.NumberFormat('sk', { style: 'currency', currency: 'EUR' });
export function format(price: number) {
  return formatter.format(price);
}
