import React from 'react';

export interface StaticRouterContextValue {
  statusCode: number;
  url: string;

  setStatusCode(statusCode: number);
  setUrl(url: string);
}

const defaultContext: StaticRouterContextValue = {
  statusCode: null,
  url: null,

  setStatusCode(statusCode: number) {
    defaultContext.statusCode = statusCode;
  },
  setUrl(url: string) {
    defaultContext.url = url;
  },
};

const StaticRouterContext = React.createContext<StaticRouterContextValue>(defaultContext);

export default StaticRouterContext;
