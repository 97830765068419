export * from 'app/locales/countries';

export const NOT_FOUND_TITLE = {
  sk: 'Stránka nenájdená',
};
export const NOT_FOUND_DESCRIPTION = {
  sk: 'Stránku, ktorú hľadáte sme nenašli!',
};
export const NOT_FOUND_GOTO = {
  sk: 'Stránka môže byť odstránená alebo nedostupná. Pokračujte prosím na <a href="/">hlavnú stránku</a>.',
};
export const LOAD_ERROR_MESSAGE = {
  sk: 'Nepodarilo sa načítať obsah kvôli chybe.',
};
export const LOAD_ERROR_RETRY = {
  sk: 'Skúsiť znova',
};
export const NOT_FOUND_ERROR_TITLE = {
  sk: 'Stránka nenájdená',
};
export const COUNTRIES_FAVORITE = {
  sk: 'Obľúbené',
};
export const COUNTRIES_OTHERS = {
  sk: 'Ostatné',
};
export const YES = {
  sk: 'áno',
};
export const NO = {
  sk: 'nie',
};
export const SERVER_ERROR_TITLE = {
  sk: 'Neočakávaná chyba',
};
export const SERVER_ERROR_APOLOGIES = {
  sk: 'Ospravedlňujeme sa za výpadok',
};
export const SERVER_ERROR_MESSAGE = {
  sk: 'V aplikácii nastala chyba, s ktorou sme nerátali.',
};
export const SERVER_ERROR_MESSAGE_WITH_REPORT = {
  sk:
    'V aplikácii nastala chyba, s ktorou sme nerátali. Ak nám chcete pomôcť, čo najrýchlejšie problém odstrániť, kliknite na tlačítko nižšie a vyplňte dodatočné informácie o tom, ako ste na chybu narazili.',
};
export const SERVER_ERROR_REPORT = {
  sk: 'Nahlásiť detail chyby',
};
export const SERVER_ERROR_SENTRY_ERROR_FORM_ENTRY = {
  sk: 'Niektoré položky sú neplatné. Prosím skontrolujte ich.',
};
export const SERVER_ERROR_SENTRY_ERROR_GENERIC = {
  sk: 'Nastala neočdakávaná chyba pri odosielaní tvojho reportu. Skúste to prosím znova.',
};
export const SERVER_ERROR_SENTRY_LABEL_CLOSE = {
  sk: 'Zavrieť',
};
export const SERVER_ERROR_SENTRY_LABEL_COMMENTS = {
  sk: 'Čo presne sa stalo? Čo ste chceli urobiť?',
};
export const SERVER_ERROR_SENTRY_LABEL_EMAIL = {
  sk: 'E-mail',
};
export const SERVER_ERROR_SENTRY_LABEL_NAME = {
  sk: 'Vaše meno',
};
export const SERVER_ERROR_SENTRY_LABEL_SUBMIT = {
  sk: 'Odoslať',
};
export const SERVER_ERROR_SENTRY_SUBTITLE = {
  sk: 'Naši administrátori boli informovaní',
};
export const SERVER_ERROR_SENTRY_SUBTITLE2 = {
  sk: 'Ak nám chcete pomôcť, napíšte nám, čo sa stalo.',
};
export const SERVER_ERROR_SENTRY_SUCCESS_MESSAGE = {
  sk: 'Váš report bol úspešne odoslaný. Ďakujeme',
};
export const SERVER_ERROR_SENTRY_TITLE = {
  sk: 'Vyzerá to, že máme nejaký technický problém.',
};
export const DATE_FORMAT = {
  sk: 'dd.MM.yyyy',
};
export const DAY_FORMAT = {
  sk: 'eeee',
};
export const DATETIME_FORMAT = {
  sk: 'dd.MM.yyyy HH:mm:ss',
};
export const TIME_FORMAT = {
  sk: 'HH:mm:ss',
};
export const DATETIME_PICKER_CANCEL = {
  sk: 'Zrušiť',
};
export const DATETIME_PICKER_CLEAR = {
  sk: 'Zrušiť',
};
export const DATETIME_PICKER_OK = {
  sk: 'Potvrdiť',
};
export const DATETIME_PICKER_TODAY = {
  sk: 'Dnes',
};
export const DAY_MENU_UNITS_LEFT = {
  sk: ['zostáva ešte jedna porcia', 'zostávajú ešte {{ count }} porcie', 'zostáva ešte {{ count }} porcií'],
};
