import { getSessionStorage } from 'app/services/storages';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

interface OwnProps {
  children: React.ReactNode;
}

type Props = OwnProps;

export function getScrollTop(): number {
  return 'scrollY' in window
    ? window.scrollY // normal browsers
    : document.documentElement.scrollTop; // IE
}

const ScrollToTop: React.FunctionComponent<Props> = ({ children }) => {
  // const location = useLocation<LocationState>();
  const location = useLocation();

  useEffect(() => {
    // window.history.scrollRestoration = 'manual';

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (location.state?.ignoreScroll) {
      return;
    }

    const scroll = getSessionStorage().getItem(`ScrollToTop__scroll__${location.key}`);
    if (scroll) {
      const y = parseFloat(scroll);
      window.scrollTo(0, y);
    }

    return () => {
      getSessionStorage().setItem(`ScrollToTop__scroll__${location.key}`, `${getScrollTop()}`);
    };
  }, [location]);

  return <>{children}</>;
};

export default React.memo<Props>(ScrollToTop);
