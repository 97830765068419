import Roboto300Woff from 'styles/fonts/roboto-v20-latin-ext_latin-300.woff';
import Roboto300Woff2 from 'styles/fonts/roboto-v20-latin-ext_latin-300.woff2';
import Roboto400Woff from 'styles/fonts/roboto-v20-latin-ext_latin-regular.woff';
import Roboto400Woff2 from 'styles/fonts/roboto-v20-latin-ext_latin-regular.woff2';
import Roboto500Woff from 'styles/fonts/roboto-v20-latin-ext_latin-500.woff';
import Roboto500Woff2 from 'styles/fonts/roboto-v20-latin-ext_latin-500.woff2';
import Roboto700Woff from 'styles/fonts/roboto-v20-latin-ext_latin-700.woff';
import Roboto700Woff2 from 'styles/fonts/roboto-v20-latin-ext_latin-700.woff2';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

// https://google-webfonts-helper.herokuapp.com/fonts/roboto?subsets=latin,latin-ext
const fonts: CSSProperties['@font-face'] = [
  {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    fontDisplay: 'swap',
    src: `local('Roboto Light'), local('Roboto-Light'), url('${Roboto300Woff2}') format('woff2'), url('${Roboto300Woff}') format('woff')`,
  },
  {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontDisplay: 'swap',
    src: `local('Roboto'), local('Roboto-Regular'), url('${Roboto400Woff2}') format('woff2'), url('${Roboto400Woff}') format('woff')`,
  },
  {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontDisplay: 'swap',
    src: `local('Roboto Medium'), local('Roboto-Medium'), url('${Roboto500Woff2}') format('woff2'), url('${Roboto500Woff}') format('woff')`,
  },
  {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    fontDisplay: 'swap',
    src: `local('Roboto Bold'), local('Roboto-Bold'), url('${Roboto700Woff2}') format('woff2'), url('${Roboto700Woff}') format('woff')`,
  },
];

export default fonts;
