import { createMuiTheme } from '@material-ui/core/styles';
import fonts from 'app/styles/fonts';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    common: { black: '#000', white: 'rgba(255, 255, 255, 1)' },
    background: { paper: 'rgba(255, 255, 255, 1)', default: 'rgba(255, 255, 255, 1)' },
    primary: {
      light: 'rgba(255, 98, 135, 1)',
      main: 'rgba(247, 87, 125, 1)',
      dark: 'rgba(191, 65, 95, 1)',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    secondary: {
      light: 'rgba(255, 225, 137, 1)',
      main: 'rgba(255, 225, 137, 1)',
      dark: 'rgba(226, 199, 124, 1)',
      contrastText: 'rgba(216, 76, 110, 1)',
    },
    error: { light: 'rgba(245, 166, 35, 1)', main: '#f44336', dark: '#d32f2f', contrastText: '#fff' },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': fonts,
      },
    },
  },
});

export default theme;
