import skLocale from 'date-fns/locale/sk';

export const dateFnsLocales = {
  sk: skLocale,
};

export function parseDuration(value: string): number {
  // Return number of seconds
  if (value.includes(':')) {
    const parts = value.split(':');
    return parts.reduce((acc, cur) => 60 * acc + parseInt(cur, 10), 0) * 60;
  } else {
    // Default is hour
    return Math.round(parseFloat(value.replace(',', '.')) * 3600);
  }
}

export function isDuration(value: string): boolean {
  return /^((\d+:[0-5]\d)|((\d+)([,.]\d+)?))$/.test(value);
}

export function secondsToHours(value: number): string {
  return (value / 3600).toFixed(2);
}
