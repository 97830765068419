import memoize from 'lodash/memoize';
import { compile } from 'path-to-regexp';
import { stringify } from 'query-string';

export const HOME_ROUTE = `/`;
const AUTH_ROUTE = `${HOME_ROUTE}auth/`;
export const LOGIN_ROUTE = `${AUTH_ROUTE}login/`;
export const REGISTER_ROUTE = `${AUTH_ROUTE}register/`;
export const FORGOTTEN_PASSWORD_ROUTE = `${AUTH_ROUTE}forgotten-password/`;
export const RESET_PASSWORD_ROUTE = `${AUTH_ROUTE}reset-password/:token/`;

export const PROFILE_ROUTE = `/profile/`;

export const TERMS_ROUTE = `/terms/`;
export const CONTACT_ROUTE = `/contact/`;
export const CREDIT_ROUTE = `/credit/`;
export const ORDERS_ROUTE = `/orders/`;

export const ADMIN_ROUTE = `/admin/`;
export const ADMIN_USERS_ROUTE = `${ADMIN_ROUTE}users/`;
export const ADMIN_USERS_CREATE_ROUTE = `${ADMIN_USERS_ROUTE}create/`;
export const ADMIN_USERS_EDIT_ROUTE = `${ADMIN_USERS_ROUTE}:id/edit/`;
export const ADMIN_MENUS_ROUTE = `${ADMIN_ROUTE}menus/`;
export const ADMIN_MENUS_CREATE_ROUTE = `${ADMIN_MENUS_ROUTE}create/`;
export const ADMIN_MENUS_EDIT_ROUTE = `${ADMIN_MENUS_ROUTE}:id/edit/`;
export const ADMIN_ORDERS_ROUTE = `${ADMIN_ROUTE}orders/`;
export const ADMIN_ORDERS_CREATE_ROUTE = `${ADMIN_ORDERS_ROUTE}create/`;
export const ADMIN_ORDERS_EDIT_ROUTE = `${ADMIN_ORDERS_ROUTE}:id/edit/`;
export const ADMIN_DELIVERIES_ROUTE = `${ADMIN_ROUTE}deliveries/`;
export const ADMIN_CREDIT_TRANSACTIONS_ROUTE = `${ADMIN_ROUTE}credit-transactions/`;
// export const ADMIN_ORDERS_CREATE_ROUTE = `${ADMIN_ORDERS_ROUTE}create/`;
// export const ADMIN_ORDERS_EDIT_ROUTE = `${ADMIN_ORDERS_ROUTE}:id/edit/`;

const cachedPath2RegexCompile = memoize(compile);

export function url(
  pattern: string,
  keys: { [name: string]: string | number },
  params?: { [name: string]: string | number },
) {
  const re = cachedPath2RegexCompile(pattern);
  let compiled = re(keys);

  if (params) {
    const qs = stringify(params);
    compiled = `${compiled}?${qs}`;
  }

  return compiled;
}
