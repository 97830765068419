import ServerError from 'app/components/ServerError';
import { getLogger } from 'app/services/logger';
import React from 'react';

const logger = getLogger('components/ErrorBoundary');

interface OwnProps {}
interface State {
  error: any;
}

type Props = OwnProps;

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    error: null,
  };

  public componentDidCatch(error, errorInfo) {
    this.setState({ error });
    logger.error(error, errorInfo);
  }

  public render() {
    if (this.state.error) {
      return <ServerError />;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
