import { config } from '@fortawesome/fontawesome-svg-core';
import App from 'app/components/App/App';
import createApollo from 'app/services/apollo';
import { connectSentry, getLogger, LEVELS } from 'app/services/logger';
import Sentry from 'app/services/sentry';
import React from 'react';
import { hydrate, render } from 'react-dom';
import * as Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

if (ENV.SSR) {
  config.autoAddCss = false;
}

if (Sentry) {
  connectSentry(Sentry, LEVELS.WARN);
}

const logger = getLogger('client');

declare const window: any;

const props = window.PROPS || {};
const initialCacheState = window.APOLLO_CACHE || {};

if (Sentry) {
  Sentry.configureScope((scope) => {
    scope.setUser(props.requestUser);
  });
}

const apollo = createApollo(null, initialCacheState);

function renderApp(AppComponent) {
  const target = document.getElementById('root');

  if (target) {
    (ENV.SSR ? hydrate : render)(
      <ApolloProvider client={apollo}>
        <BrowserRouter>
          <AppComponent {...props} />
        </BrowserRouter>
      </ApolloProvider>,
      target,
    );
  }
}

async function run() {
  await Loadable.preloadReady();
  renderApp(App);
}

run().catch((err) => {
  logger.error(err);
});

if (module.hot) {
  module.hot.accept(['app/components/App/App', 'app/locales/client'], () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const NextApp = require('app/components/App/App').default;
    renderApp(NextApp);
  });
}
