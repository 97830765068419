import cx from 'classnames';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as LogoBig } from 'images/logo-big.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(-2, -2, 5, -2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(-1, -1, 5, -1),
    },
  },
  logoSvg: {
    fill: 'currentColor',
    width: 1260 / 4,
    height: 702 / 4,
    margin: theme.spacing(3),
    display: 'inline-block',
    transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    userSelect: 'none',
  },
}));

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

const Banner: React.ForwardRefRenderFunction<HTMLDivElement, Props> = ({ className }, ref) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, className)} ref={ref}>
      <LogoBig viewBox="0 0 1260 702" className={classes.logoSvg} />
    </div>
  );
};

Banner.displayName = 'Banner';

export default React.forwardRef(Banner);
